<template>
        <h5 class="accordion-width">
            <div class="row">
                <div class="col-md-6 my-2">{{ site }}</div>
                <div class="col-md-6">
                    <span class="p-input-icon-left float-end">
                        <i class="pi pi-search" />
                        <InputText v-model="searchKeyword['global'].value" placeholder="Search" />
                    </span>
                </div>
            </div>
        </h5>
        <DataTable
        :value="checkInActivity"
        ref="dt"
        exportFilename="Check-Ins"
        :loading="loading"
        responsiveLayout="scroll"
        v-model:selection="selectedCheckIns"
        selectionMode="single"
        @rowSelect="this.$emit('checkInInfo', selectedCheckIns)"
        v-model:filters="searchKeyword"
        filterDisplay="menu"
        @filter="calculate"
        >
            <template #empty>
                No records found.
            </template>
            <Column field="email" header="Agent" sortable></Column>
            <Column field="date" header="Date" sortable>
                <template #filter="{filterModel}">
                    <InputText v-model="filterModel.value" placeholder="mm/dd/yy"/>
                </template>
            </Column>
            <Column field="break_m" header="Break (Min)" class="text-end" sortable>
                <template #footer v-if="!searchKeyword['global'].value && rowCount">
                    {{totalBreakInMin}}
                </template>
            </Column>
            <Column field="checkIn" header="Check-In" class="text-end" dataType="numeric" sortable>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by check-In"/>
                </template>
            </Column>
            <Column field="checkOut" header="Check-Out" class="text-end" sortable>
            </Column>
            <Column field="duration_m" header="Duration (Min)" class="text-end" sortable></Column>
            <Column field="scanned" header="Scanned" class="text-end" sortable></Column>
        </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { Utility } from '../../../shared/utils';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    name: 'CheckInActivity',
    components: {
        Column,
        DataTable,
        InputText
    },
    props: {
        loading: Boolean,
        checkInActivity: Object,
        site: String
    },
    data() {
        return {
            Utility,
            selectedCheckIns: null,
            searchKeyword: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'checkIn': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
            },
            rowCount: this.checkInActivity.length
        };
    },
    watch: {
        selectedCheckIns: function() {
            this.$emit('disableTabs');
        },
        checkInActivity: function() {
            this.rowCount = this.checkInActivity.length;
        }
    },
    methods: {
        calculate(event) {
            this.totalBreakInMin = Utility.getSum('checkin', event.filteredValue);
            this.rowCount = event.filteredValue.length;
        }
    }
};
</script>
<style>
</style>
