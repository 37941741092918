<template>
        <h5 class="accordion-width">
            <div class="row">
                <div class="col-md-6 my-2">{{ assetTag }}</div>
                <div class="col-md-6">
                    <span class="p-input-icon-left float-end">
                        <i class="pi pi-search" />
                        <InputText v-model="searchKeyword['global'].value" placeholder="Search" />
                    </span>
                </div>
            </div>
        </h5>
        <DataTable :value="attributes" ref="dt" exportFilename="Attributes" :loading="loading"
        responsiveLayout="scroll" v-model:filters="searchKeyword" @filter="countRecord">
            <template #empty>
                No records found.
            </template>
            <Column field="gap_s" header="Gap(Sec)" class="attr-number-col-alignment" sortable>
                <template #footer v-if="!searchKeyword['global'].value && rowCount">
                    {{Utility.getSum('gap',this.attributes)}}
                </template>
            </Column>
            <Column field="start" header="Start" class="attr-number-col-alignment" sortable/>
            <Column field="attribute" header="Attribute" class="attr-text-col-width" sortable></Column>
            <Column field="attribute_value" header="Attribute Value" class="attr-text-col-width" sortable></Column>
            <Column field="duration_s" header="Duration(Sec)" class="attr-number-col-alignment" sortable>
                <template #footer v-if="!searchKeyword['global'].value && rowCount">
                    {{Utility.getSum('duration',this.attributes)}}
                </template>
            </Column>
        </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { Utility } from '../../../shared/utils';
import { FilterMatchMode } from 'primevue/api';
export default {
    name: 'Attributes',
    components: {
        Column,
        DataTable,
        InputText
    },
    props: {
        loading: Boolean,
        attributes: Object,
        assetTag: String
    },
    data() {
        return {
            Utility,
            searchKeyword: { 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } },
            rowCount: this.attributes.length
        };
    },
    methods: {
        countRecord(event) {
            this.rowCount = event.filteredValue.length;
        }
    }
};
</script>
<style>
.attr-number-col-alignment{
  text-align: right !important;
}
.attr-number-col-alignment, .attr-text-col-width{
    width: 12.5rem !important;
}
</style>
