<template>
    <div class="mt-3 mb-4" v-if="Object.keys(visitInfo).length != 0">
        <div class="row">
            <div class="col-md-6 fs-6 fw-bolder">Visit Analysis</div>
            <div class="col-md-6 text-end">
                <Button
                    icon="pi pi-arrow-left"
                    class="button-color-lavender-purple p-button-text"
                    @click="$router.push({ name: 'Visits' })"
                />
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-3">
                <div class="visit-name-col">
                    <span data-bs-toggle="tooltip" data-bs-placement="top" :title="visitInfo.name">
                        {{ visitInfo.name }}
                    </span>
                </div>
                <div>{{ visitInfo.buildingCode }}-{{ visitInfo.buildingName }}</div>
                <div v-if="typeof visitInfo.buildingAddress == 'string'">
                    {{ visitInfo.buildingAddress }}
                </div>
                <div v-else>
                    <div>{{ visitInfo.buildingAddress.line_1 + ' ' + visitInfo.buildingAddress.line_2 }}</div>
                    <div>{{ visitInfo.buildingAddress.city + ', ' + visitInfo.buildingAddress.state }}</div>
                    <div>{{ visitInfo.buildingAddress.zip }}</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-9">Total Work Hours</div>
                    <div class="col-md-3">{{ visitInfo.workHours }}</div>
                </div>
                <div class="row">
                    <div class="col-md-9">Total Break Hours</div>
                    <div class="col-md-3">{{ visitInfo.breakHours }}</div>
                </div>
                <div class="row">
                    <div class="col-md-9">Average Scan Time (Min)</div>
                    <div class="col-md-3">{{ visitInfo.avgScanTime }}</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-9">Total Expected</div>
                    <div class="col-md-3">{{ visitInfo.totalExpected }}</div>
                </div>
                <div class="row">
                    <div class="col-md-9">Total Scanned</div>
                    <div class="col-md-3">{{ visitInfo.totalScanned }}</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="ps-5" v-if="visitInfo.start && visitInfo.end">
                    {{ visitInfo.start }} - {{ visitInfo.end }} [{{visitInfo.duration}} days]
                </div>
                <div class="ps-5" v-else>{{ NA }}</div>
                <div class="ps-5">Field Agent{{ visitInfo.teams > 1 ? 's' : ''}}: {{ visitInfo.teams }}</div>
                <div class="ps-5">State: {{ getStatus(visitInfo.state) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import { VisitStatus } from '../../../shared/enums';
import { Utility } from '../../../shared/utils';
import { NA, NAVIGATIONCONST } from '../../../shared/constants';
export default {
    name: 'VisitInfo',
    components: {
        Button
    },
    props: {
        visitInfo: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            NA: NA,
            NAVIGATIONCONST
        };
    },
    methods: {
        getStatus(val) {
            return Utility.getKeyByValue(VisitStatus, val);
        }
    }
};
</script>
<style scoped>
.visit-name-col{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
</style>
