/* eslint-disable no-fallthrough */
/* eslint-disable no-fallthrough */
<template>
  <Dialog header="Filter" @hide="this.$emit('closeFilter');" position="topright" v-model:visible="displayFilter"
    :style="{width: '25.25rem'}" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500 mb-2">LOCATION</div>
        <Dropdown
            class="w-100"
            v-model="selectedLocation"
            placeholder="Select a Location"
            :options="locationOptions"
            optionLabel="label"
            @change="refillDropDown('Location')"
        ></Dropdown>

        <div class="text-muted fs-5 font-weight-500 mb-2 mt-3">DATE</div>
        <MultiSelect
            class="w-100"
            v-model="selectedDate"
            :options="filteredCheckInActivityForDate"
            placeholder="Select Date"
            @change="refillDropDown('Date')"
            :preselect-first="true"
        />

        <div class="text-muted fs-5 font-weight-500 mb-2 mt-3">ASSET TYPES</div>
        <MultiSelect
            class="w-100"
            v-model="selectedAssetType"
            :options="filteredCheckInActivityForAssets"
            placeholder="Select Asset Type"
            @change="refillDropDown('AssetType')"
        />

        <div class="text-muted fs-5 font-weight-500 mb-2 mt-3">AGENTS</div>
        <MultiSelect
            class="w-100"
            v-model="selectedTeamMembers"
            :options="filteredCheckInActivityForMembers"
            placeholder="Select Team Member"
            @change="refillDropDown('Agents')"
        />

        <div class="text-muted fs-5 font-weight-500 mb-2 mt-3">CHECK-IN</div>
        <Dropdown
            class="w-100"
            v-model="selectedCheckInTime"
            :options="checkInOptions"
            placeholder="Select Check-In"
            optionLabel="label"
            @change="refillDropDown('CheckIn')"
            :disabled= "visible"
        ></Dropdown>

        <div class="text-muted fs-5 font-weight-500 mb-2 mt-3">ATTRIBUTE</div>
        <Dropdown
            class="w-100"
            v-model="selectedAttribute"
            :options="attributeOptions"
            placeholder="Select Attribute"
            optionLabel="label"
        ></Dropdown>

        <Button class="filterText button-size justify-content-center button-color-lavender-purple mt-4 w-100"
        @click="applyfilter()">APPLY</Button>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import { allText } from '../../../shared/constants/constants';
export default {
    name: 'VisitAnalysisFilter',
    components: {
        Button,
        Dialog,
        Dropdown,
        MultiSelect
    },
    props: {
        displayFilter: Boolean,
        data: Object,
        checkInActivity: Array
    },
    updated() {
        if (this.displayFilter) {
            this.init();
        }
    },
    data() {
        return {
            allText: allText.all,
            locationOptions: [],
            dateOptionsOptions: [],
            attributeOptions:[],
            selectedLocation: { locationId: '0', label: allText.all },
            selectedDate: [],
            selectedAssetType: [],
            selectedTeamMembers: [],
            selectedCheckInTime: { id: '0', label: allText.all },
            selectedAttribute: { id: '0', label: allText.all },
            visible: false
        };
    },
    methods: {
        init() {
            if (this.selectedDate?.length >0 && this.selectedTeamMembers?.length >0) {
                            this.visible = false;
                    } 
                    else {
                            this.visible = true;
                            this.selectedCheckInTime= { id: '0', label: allText.all }
             }

            this.locationOptions = [];
            this.locationOptions.push({ locationId: '0', label: allText.all });
            this.attributeOptions = [];
            this.attributeOptions.push({ id: '0', label: allText.all });
            const locationIds = new Set();
            this.data.locations.forEach(x => {
                if (!locationIds.has(x.locationId)) {
                    locationIds.add(x.locationId);
                    this.locationOptions.push({ locationId: x.locationId, label: `${ x.locationId }-${ x.name }` });
                }
            });
            if(!this.selectedDate?.length>0 && !this.selectedMembers?.length>0)
            {
                  this.checkInOptions = [];
             this.checkInOptions.push({ id: '0', label: allText.all });
            }
            const attributeIds = new Set();
            this.data.attributes.forEach(x => {
                if (!attributeIds.has(x.attribute)) {
                    attributeIds.add(x.attribute);
                    this.attributeOptions.push({ id: x.attribute, label: `${ x.attribute }` });
                }
            });
            this.attributeOptions = [...new Set(this.attributeOptions)];

            this.filteredCheckInActivityForDate = this.filteredCheckInActivityForDate?.length>0? this.filteredCheckInActivityForDate  : this.data.agents.filter(x => x.date && x.date!=" ").map(x=>x.date);
            this.filteredCheckInActivityForDate = [...new Set(this.filteredCheckInActivityForDate)];

            this.filteredCheckInActivityForAssets = this.filteredCheckInActivityForAssets?.length>0? this.filteredCheckInActivityForAssets: this.data.assets.map(x=>x.assetType);
            this.filteredCheckInActivityForAssets = [...new Set(this.filteredCheckInActivityForAssets)];

            this.filteredCheckInActivityForMembers = this.filteredCheckInActivityForMembers?.length>0? this.filteredCheckInActivityForMembers  :this.data.agents.map(x=>x.memberName);
            this.filteredCheckInActivityForMembers = [...new Set(this.filteredCheckInActivityForMembers)];
           
        },
        applyfilter() {
            this.$emit('applyFilter', {
                selectedLocation: this.selectedLocation,
                selectedAttribute: this.selectedAttribute,
                selectedDate: this.selectedDate,
                selectedAssetType: this.selectedAssetType,
                selectedTeamMembers: this.selectedTeamMembers,
                selectedCheckInTime: this.selectedCheckInTime
            });
        },
        clearFilters() {
            this.selectedLocation = { locationId: '0', label: allText.all };
            this.selectedDate = [];
            this.selectedAssetType = [];
            this.selectedTeamMembers = [];
            this.selectedCheckInTime = [];
            this.selectedAttribute = { id: '0', label: allText.all };
            this.selectedCheckInTime= { id: '0', label: allText.all };
            this.filteredCheckInActivityForDate =[];
            this.filteredCheckInActivityForCheckInTime = [];
            this.filteredCheckInActivityForMembers = [];
            this.filteredCheckInActivityForAssets = [];
            this.$emit('applyFilter', {
                selectedLocation: this.selectedLocation,
                selectedAttribute: this.selectedAttribute,
                selectedDate: this.selectedDate,
                selectedAssetType: this.selectedAssetType,
                selectedTeamMembers: this.selectedTeamMembers,
                selectedCheckInTime: this.selectedCheckInTime
            });
        },
        refillDropDown(onChange) {
                if (this.selectedDate?.length >0 && this.selectedTeamMembers?.length >0) {
                            this.visible = false;
                    } 
                    else {
                            this.visible = true;
                            this.selectedCheckInTime= { id: '0', label: allText.all };
                    }
            const conditions = this.getCondtions();
            switch (onChange) {
            case 'Location': {
                this.selectedDate = [];
                this.selectedAssetType = [];
                this.selectedTeamMembers = [];
                this.selectedCheckInTime = [];
                this.selectedAttribute = { id: '0', label: allText.all };
                this.selectedCheckInTime= { id: '0', label: allText.all }; 
                this.filteredCheckInActivityForDate =
                    this.data.agents
                        .filter(x => conditions.location(x) && x.date)
                        .map(x=>x.date);
                this.filteredCheckInActivityForDate = [...new Set(this.filteredCheckInActivityForDate)];
            }
            // eslint-disable-next-line no-fallthrough
            case 'Date': {
                this.filteredCheckInActivityForAssets =
                        this.data.assets
                            .filter(x => ([conditions.location, conditions.date].every(c => c(x))))
                            .map(x=>x.assetType);
                this.filteredCheckInActivityForAssets = [...new Set(this.filteredCheckInActivityForAssets)]; 
                this.selectedAssetType = [];
                this.selectedTeamMembers = [];
                this.selectedCheckInTime = [];
                this.selectedAttribute = { id: '0', label: allText.all };
                this.selectedCheckInTime= { id: '0', label: allText.all };          
            }
            // eslint-disable-next-line no-fallthrough
            case 'AssetType': {
                const agentEmails = this.data.assets.filter(x =>
                    this.selectedAssetType.length == 0 ||
                    this.selectedAssetType.includes(x.assetType)
                ).map(x => x.email);
                if(this.selectedAssetType?.length>0)
                {
                this.filteredCheckInActivityForMembers =
                    this.data.agents
                        .filter(x => ([conditions.location, conditions.date].every(c => c(x))) && (agentEmails.includes(x.email)))
                        .map(x=>x.memberName);
                } else{
                    this.filteredCheckInActivityForMembers =
                    this.data.agents
                        .filter(x => ([conditions.location, conditions.date].every(c => c(x))))
                        .map(x=>x.memberName);
                }

                this.selectedTeamMembers = [];
                this.selectedCheckInTime = [];
                this.selectedAttribute = { id: '0', label: allText.all };
                this.selectedCheckInTime= { id: '0', label: allText.all };

                this.filteredCheckInActivityForMembers = [...new Set(this.filteredCheckInActivityForMembers)];
            }
            // eslint-disable-next-line no-fallthrough
            case 'Agents': {
                if(this.selectedDate?.length>0 && this.selectedTeamMembers?.length>0){
                this.filteredCheckInActivityForCheckInTime =
                        this.data.checkIns
                            .filter(x => ([
                                conditions.location, conditions.date, conditions.member
                            ].every(c => c(x))));
                this.checkInOptions = [];
                const checkInOptionsIds = new Set();
                this.checkInOptions.push({ id: '0', label: allText.all });
                this.filteredCheckInActivityForCheckInTime.forEach(x => {
                    if (!checkInOptionsIds.has(x.memberName + x.checkIn)) {
                    checkInOptionsIds.add(x.memberName + x.checkIn);
                    this.checkInOptions.push({ id: x.checkIn, label: `${ x.memberName }-${ x.checkIn?x.checkIn:'' }` });
                }                  
                });
                this.checkInOptions = [...new Set(this.checkInOptions)];
                } 
                
                this.selectedCheckInTime = [];
                this.selectedAttribute = { id: '0', label: allText.all };
                this.selectedCheckInTime= { id: '0', label: allText.all };
            }
            // eslint-disable-next-line no-fallthrough
            case 'CheckIn': {
                this.selectedAttribute = { id: '0', label: allText.all };
                const attributeIds = new Set();
                this.data.attributes
                    .filter(x => ([
                        conditions.location, conditions.date, conditions.member, conditions.checkIn].every(c => c(x))))
                    .forEach(x => {
                        if (!attributeIds.has(x.attribute)) {
                            attributeIds.add(x.attribute);
                            this.attributeOptions.push({ id: x.attribute, label: `${ x.attribute }` });
                        }
                    });
            }
            }
        },
        getCondtions() {
            const conditions = {};
            conditions.location = x =>
                (this.selectedLocation.label == this.allText || x.locationId == this.selectedLocation.locationId);

            conditions.date = x =>
                this.selectedDate.length == 0 || this.selectedDate.includes(x.date);

            conditions.member = x =>
                this.selectedTeamMembers.length == 0 || this.selectedTeamMembers.includes(x.memberName);

            conditions.assetTypes = x =>
                this.selectedAssetType.length == 0 || this.selectedAssetType.includes(x.assetType);

            conditions.checkIn = x=>
                (this.selectedCheckInTime.label == this.allText || x.checkIn == this.selectedCheckInTime.id);

            conditions.attributes = x=>
                (this.selectedAttribute.label == this.allText || x.attribute == this.selectedAttribute.id);

            return conditions;
        }
    }
};
</script>
