<template>
  <div class="container container-width analysis">
    <div v-if="!loading">
      <VisitInfo :visitInfo="visitInfo" />
      <div class="card p-3 mt-3">
            <TabView>
                <TabPanel header="Sites">
                    <Locations ref="location" :loading="loading" :locations="locations" @locationInfo="onLocationInfo"
                    @disableTabs="(showAttribute = showAssets = false )"
                    :buildingCode="visitInfo.buildingCode" @downloadData="downloadData"/>
                </TabPanel>
                <TabPanel header="Agents" :disabled="!isAgentCheckinAvailable">
                    <Agents v-if="isAgentCheckinAvailable" ref="agents" :loading="loading" :agents="agents" @agentInfo="onAgentInfo"
                    @disableTabs="(showAttribute = showAssets = false )" :site="site"/>
                </TabPanel>
                <TabPanel header="Check-Ins" :disabled="!isAgentCheckinAvailable">
                    <CheckInActivity v-if="isAgentCheckinAvailable" ref="checkInActivity" :loading="loading" :site="site"
                    :checkInActivity="checkInActivity" @checkInInfo="onCheckInInfo"
                    @disableTabs="(showAttribute = showAssets = false )"/>
                </TabPanel>
                <TabPanel header="Assets" :disabled="!showAssets">
                    <Assets v-if="showAssets" ref="asset" :loading="loading" :assets="assets" @assetInfo="onAssetInfo"
                    @disableTabs="showAttribute = false" :site="site"/>
                </TabPanel>
                <TabPanel header="Attributes" :disabled="!showAttribute">
                    <Attributes v-if="showAttribute" ref="attributes" :loading="loading" :attributes="attributes"
                    :assetTag="assetTag"/>
                </TabPanel>
            </TabView>
        </div>
    </div>
    <ProgressSpinner
      class="p-component-overlay position-fixed w-100 h-100"
      strokeWidth="1"
      v-if="displayLoader"
    />
  </div>
</template>

<script>
import { NAVIGATIONCONST, NA } from '../../shared/constants';
import { allText } from '../../shared/constants/constants';
import { Utility } from '../../shared/utils';
import ProgressSpinner from 'primevue/progressspinner';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import {
    Locations,
    Agents,
    CheckInActivity,
    VisitInfo,
    Attributes,
    Assets
} from './components';
import { timeAnalysisUrl, jsonToExcelUrl } from '../../shared/constants/constants';
import { sendRequest } from '../../shared/utils';
import { FileStorageService, AssetService, VisitService } from '../../apis';
import { VisitActivityState } from '../../shared/enums';

export default {
    name: 'VisitAnalysis',
    components: {
        Locations,
        Agents,
        CheckInActivity,
        VisitInfo,
        Attributes,
        Assets,
        ProgressSpinner,
        TabView,
        TabPanel
    },
    async mounted() {
        const route = this.$route;
        this.visitId = route.params.id;
        const visit = await VisitService.getById(this.visitId);
        if (visit) {
            this.getReportData();
        } else {
            this.$router.push({ name: 'PageNotFound' });
        }
    },
    data() {
        return {
            NA: NA,
            NAVIGATIONCONST,
            visitId: null,
            data: {},
            visitInfo: {},
            locations: [],
            teamMembers: [],
            agents: [],
            checkInActivity: [],
            displayFilter: false,
            formateDate: Utility.formateDate,
            formateTime: Utility.formateTime,
            allText: allText.all,
            attributes: [],
            assets: [],
            loading: false,
            displayLoader: false,
            isAgentCheckinAvailable: false,
            showAttribute: false,
            showAssets: false,
            obj: {}
        };
    },
    methods: {
        async getReportData() {
            this.displayLoader = true;
            this.loading = true;
            const reqData = {
                visitId: this.visitId,
                nodes: ['visit', 'locations'],
                filter: {
                    locationId: '',
                    userId: '',
                    activityId: '',
                    assetId: ''
                }
            };
            this.locationAndVisitData = await sendRequest(reqData, timeAnalysisUrl);
            this.formatDateAndTime('VisitAndLocation');
            this.visitInfo = this.locationAndVisitData?.visit;
            this.locations = this.locationAndVisitData?.locations;
            this.loading = false;
            this.displayLoader = false;
        },
        formatDateAndTime(condition) {
            switch (condition) {
            case 'VisitAndLocation':
                this.locationAndVisitData.visit.start = this.formateDate(this.locationAndVisitData.visit.start, 'MM/DD/YY');
                this.locationAndVisitData.visit.end = this.formateDate(this.locationAndVisitData.visit.end, 'MM/DD/YY');
                break;
            case 'AgentCheckin':
                this.agentCheckinData?.agents?.forEach(x => {
                    x.date = this.formateDate(x.date, 'MM/DD/YY');
                });
                this.agentCheckinData.checkIns.forEach(x => {
                    x.date = this.formateDate(x.date, 'MM/DD/YY');
                    x.checkIn = this.formateTime(x.checkIn, 'HH:mm:ss');
                    x.checkOut = this.formateTime(x.checkOut, 'HH:mm:ss');
                });
                break;
            case 'Assets':
                this.assetData?.assets?.forEach(x => {
                    x.date = this.formateDate(x.date, 'MM/DD/YY');
                    x.checkIn = this.formateTime(x.checkIn, 'HH:mm:ss');
                    x.start = this.formateTime(x.start, 'HH:mm:ss');
                });
                break;
            case 'Attribute':
                this.attributes.forEach(x => {
                    x.date = this.formateDate(x.date, 'MM/DD/YY');
                    x.start = this.formateTime(x.start, 'HH:mm:ss');
                    x.end = this.formateTime(x.end, 'HH:mm:ss');
                    x.checkIn = this.formateTime(x.checkIn);
                });
                break;
            }
        },
        async onLocationInfo(locationInfo) {
            this.site = locationInfo.site;
            this.displayLoader = true;
            this.locationId = locationInfo.locationId;
            this.assetType = locationInfo.assetType;
            const reqData = {
                visitId: this.visitId,
                nodes: ['agents', 'checkIns'],
                filter: {
                    locationId: '',
                    userId: '',
                    activityId: '',
                    assetId: '',
                    assetType: ''
                }
            };
            this.agentCheckinData = await sendRequest(reqData, timeAnalysisUrl);
            this.formatDateAndTime('AgentCheckin');
            this.agents = this.agentCheckinData?.agents.filter(x => x.locationId == locationInfo.locationId || x.locationId == '');
            this.checkInActivity = this.agentCheckinData?.checkIns.filter(x => x.locationId == locationInfo.locationId ||
            x.locationId == '');
            this.isAgentCheckinAvailable = true;
            this.displayLoader = false;
        },
        onAgentInfo(agentInfo) {
            this.agentId = agentInfo.userId;
            this.checkInActivity = this.agentCheckinData?.checkIns.filter(x => x.userId == agentInfo.userId &&
            (x.what === VisitActivityState.CheckIn || x.what == VisitActivityState.CheckOut));
        },
        async onCheckInInfo(checkinInfo) {
            this.displayLoader = true;
            this.activityId = checkinInfo.activityId;
            const reqData = {
                visitId: this.visitId,
                nodes: ['assets'],
                filter: {
                    locationId: this.locationId,
                    userId: checkinInfo.userId,
                    activityId: this.activityId,
                    assetId: '',
                    assetType: this.assetType
                }
            };
            this.assetData = await sendRequest(reqData, timeAnalysisUrl);
            this.formatDateAndTime('Assets');
            this.assets = this.assetData?.assets;
            this.showAssets = true;
            this.displayLoader = false;
        },
        async onAssetInfo(assetInfo) {
            this.assetTag = assetInfo.assetTag;
            this.displayLoader = true;
            this.attributeData = await AssetService.getById(assetInfo.assetId);
            const fileredVal = this.attributeData.scanned.filter(x => x.checkInId == this.activityId);
            this.attributes = [];
            fileredVal.forEach(x => {
                this.obj.userId = x.userId;
                this.obj.assetId = assetInfo.assetId || 0;
                Object.keys(x.data).forEach(y => {
                    this.obj.attribute = y;
                    this.obj.attribute_value = x.data[y]?.value ? x.data[y]?.value : x.data[y];
                    this.obj.start = x.duration.verifications[y]?.start.seconds;
                    this.obj.end = x.duration.verifications[y]?.end.seconds;
                    this.obj.duration_s = (x.duration.verifications[y]?.milliseconds)/1000 || 0;
                    this.attributes.push({ ...this.obj });
                });
            });
            this.calculateGap();
            this.formatDateAndTime('Attribute');
            this.showAttribute = true;
            this.displayLoader = false;
        },
        calculateGap() {
            this.attributes.sort((f, s) => {
                let returnValue = 0;
                returnValue = Utility.compareData(f.start, s.start);
                if (returnValue == 0) {
                    returnValue = Utility.compareData(f.end, s.end);
                }
                return returnValue;
            });
            let previousTime = null;
            for (let i = 0; i < this.attributes.length; i++) {
                if (previousTime == null) {
                    previousTime = this.attributes[i].end;
                    this.attributes[i].gap_s = 0;
                } else {
                    this.attributes[i].gap_s = (this.attributes[i].start - previousTime);
                    previousTime = this.attributes[i].end;
                }
            }
        },
        async downloadData(data) {
            this.displayLoader = true;
            const reqData = {
                visitId: this.visitId,
                nodes: ['visit', 'locations', 'agents', 'checkIns', 'assets', 'attributes'],
                filter: {
                    locationId: data.locationId,
                    userId: '',
                    activityId: '',
                    assetId: '',
                    assetType: data.assetType
                }
            };
            const res = await sendRequest(reqData, jsonToExcelUrl);
            const downloadURL = await FileStorageService.getDownloadURL(res.fileName);
            window.open(downloadURL);
            this.displayLoader = false;
        }
    }
};
</script>
<style>
.back-arrow {
  margin-left: 0.75rem;
}
.header-base {
  background: #3f51b5;
  color: #fff;
  padding: 3px 10px;
  margin-bottom: 0;
}
.analysis .p-datatable .p-datatable-tfoot > tr > td,
.analysis .p-datatable .p-datatable-thead > tr > th {
  background: #dcdcdc;
}
.p-progress-spinner-svg {
  width: 6.25rem !important;
}
.text-end > .p-column-header-content{
    float: right !important;
}
.download-icon-size{
  font-size: 1.375rem !important;
}
</style>
