<template>
        <h5 class="accordion-width">
            <div class="row">
                <div class="col-md-6 my-2">{{ site }}</div>
                <div class="col-md-6">
                    <span class="p-input-icon-left float-end">
                        <i class="pi pi-search" />
                        <InputText v-model="searchKeyword['global'].value" placeholder="Search" />
                    </span>
                </div>
            </div>
        </h5>
        <DataTable
        :value="agents"
        :loading="loading"
        exportFilename="Agents"
        ref="dt"
        responsiveLayout="scroll"
        v-model:selection="selectedAgent"
        selectionMode="single"
        @rowSelect="this.$emit('agentInfo', selectedAgent)"
        v-model:filters="searchKeyword"
        filterDisplay="menu"
        @filter="calculate"
        >
            <template #empty>
                No records found.
            </template>
            <Column field="site" header="Site"></Column>
            <Column field="email" header="Agent" sortable>
                <template #footer v-if="!searchKeyword['global'].value  && rowCount">
                    {{uniqueAgentsCount}}
                </template>
            </Column>
            <Column field="memberName" header="Name" sortable></Column>
            <Column field="date" header="Date" sortable>
                <template #filter="{filterModel}">
                    <InputText v-model="filterModel.value" placeholder="mm/dd/yy"/>
                </template>
            </Column>
            <Column field="workHours" header="Working (Hrs)" class="text-end" sortable>
                <template #footer v-if="!searchKeyword['global'].value  && rowCount">
                    {{totalWorkHours}}
                </template>
            </Column>
            <Column field="breakHours" header="Break (Hrs)" class="text-end" sortable>
                <template #footer v-if="!searchKeyword['global'].value  && rowCount">
                    {{totalBreakHours}}
                </template>
            </Column>
            <Column field="scanned" header="Scanned" class="text-end" sortable>
                <template #footer v-if="!searchKeyword['global'].value && rowCount">
                    {{totalScan}}
                </template>
            </Column>
            <Column field="avg" header="Average (Min) / Device" class="text-end" sortable></Column>
        </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { Utility } from '../../../shared/utils';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    name: 'Agents',
    components: {
        Column,
        DataTable,
        InputText
    },
    data() {
        return {
            Utility,
            selectedAgent: null,
            searchKeyword: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
            },
            rowCount: this.agents.length
        };
    },
    props: {
        loading: Boolean,
        agents: Object,
        site: String
    },
    watch: {
        selectedAgent: function() {
            this.$emit('disableTabs');
        },
        agents: function() {
            this.rowCount = this.agents.length;
        }
    },
    methods: {
        calculate(event) {
            this.uniqueAgentsCount = Utility.getUniqueCount(event.filteredValue?.map(x=>x.email));
            this.totalWorkHours = Utility.getSum('workHours', event.filteredValue);
            this.totalBreakHours = Utility.getSum('breakHours', event.filteredValue);
            this.totalScan = Utility.getSum('scanned', event.filteredValue);
            this.rowCount = event.filteredValue.length;
        }
    }
};
</script>
<style>
</style>
