<template>
        <h5 class="accordion-width">
          <div class="row">
            <div class="col-md-6 my-2">{{ site }}</div>
            <div class="col-md-6">
              <span class="p-input-icon-left float-end">
                <i class="pi pi-search" />
                <InputText v-model="searchKeyword['global'].value" placeholder="Search" />
              </span>
            </div>
          </div>
        </h5>
      <DataTable
        :value="assets"
        ref="dt"
        exportFilename="Assets"
        :loading="loading"
        responsiveLayout="scroll"
        v-model:selection="selectedAsset"
        selectionMode="single"
        @rowSelect="this.$emit('assetInfo', selectedAsset)"
        v-model:filters="searchKeyword"
        filterDisplay="menu"
        @filter="calculate"
      >
        <template #empty>
          No records found.
        </template>
        <Column field="email" header="Agent" sortable>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by email"/>
          </template>
        </Column>
        <Column field="date" header="Date" sortable>
          <template #filter="{filterModel}">
            <InputText v-model="filterModel.value" placeholder="mm/dd/yy"/>
          </template>
        </Column>
        <Column field="checkIn" header="Check-In" class="text-end" dataType="numeric" sortable>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by check-In"/>
          </template>
        </Column>
        <Column field="gap_m" header="Gap (Min)" class="text-end" sortable>
          <template #footer v-if="!searchKeyword['global'].value && rowCount">
            {{ totalGapInMin }}
          </template>
        </Column>
        <Column field="start" header="Start" class="text-end" sortable/>
        <Column field="duration_m" header="Duration (Min)" class="text-end" sortable>
          <template #footer v-if="!searchKeyword['global'].value && rowCount">
            {{ totalDurationInMin }}
          </template>
        </Column>
        <Column field="assetType" header="Asset Type" sortable/>
        <Column
          field="assetTag"
          header="Asset Tag"
          :footer="!searchKeyword['global'].value && uniqueAssetTagCount && rowCount"
          sortable
        />
      </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import { Utility } from '../../../shared/utils';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    name: 'Assets',
    components: {
        Column,
        DataTable,
        InputText
    },
    props: {
        loading: Boolean,
        assets: Object,
        site: String
    },
    data() {
        return {
            Utility,
            selectedAsset: null,
            searchKeyword: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'email': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'checkIn': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
            },
            rowCount: this.assets.length
        };
    },
    watch: {
        selectedAsset: function() {
            this.$emit('disableTabs');
        },
        assets: function() {
            this.rowCount = this.assets.length;
        }
    },
    methods: {
        getAssetTagCount() {
            return new Set(this.assets.map(asset => asset.assetTag)).size;
        },
        calculate(event) {
            this.totalGapInMin = Utility.getSum('gap_m', event.filteredValue);
            this.totalDurationInMin = Utility.getSum('duration_m', event.filteredValue);
            this.uniqueAssetTagCount = Utility.getUniqueCount(event.filteredValue?.map(asset => asset.assetTag));
            this.rowCount = event.filteredValue.length;
        }
    }
};
</script>
<style>
</style>
