<template>
        <h5 class="accordion-width">
          <div class="row">
            <div class="col-md-6 my-2">{{ buildingCode}}</div>
            <div class="col-md-6">
              <span class="p-input-icon-left float-end">
                <i class="pi pi-search" />
                <InputText v-model="searchKeyword['global'].value" placeholder="Search" />
              </span>
            </div>
          </div>
        </h5>
      <DataTable
        :value="locations"
        ref="dt"
        exportFilename="Locations"
        :loading="loading"
        responsiveLayout="scroll"
        v-model:selection="selectedLocation"
        selectionMode="single"
        @rowSelect="this.$emit('locationInfo', this.selectedLocation)"
        v-model:filters="searchKeyword"
        @filter="countRecord"
      >
        <template #empty>
          No records found.
        </template>
        <Column field="locationId" header="Site" sortable>
          <template #footer v-if="!searchKeyword['global'].value">
            {{
              Utility.getUniqueCount(this.locations.map((x) => x.locationId))
            }}
          </template>
        </Column>
        <Column field="name" header="Name" sortable/>
        <Column field="assetType" header="Asset Type" sortable/>
        <Column field="expected" header="Expected" class="text-end" sortable>
          <template #footer v-if="!searchKeyword['global'].value">
            {{ Utility.getSum("expected", locations) }}
          </template>
        </Column>
        <Column field="scanned" header="Scanned" class="text-end" sortable>
          <template #footer v-if="!searchKeyword['global'].value">
            {{ Utility.getSum("scanned", locations) }}
          </template>
        </Column>
        <Column field="avg" header="Average (Min) / Device" class="text-end" sortable>
          <template #footer v-if="!searchKeyword['global'].value">
            {{ Utility.getAverage(locations) }}
          </template>
        </Column>
        <Column header="">
          <template #body="slotProps">
            <Button icon="pi pi-download" class="p-button-rounded p-button-text" @click="exportCSV(slotProps.data)" />
          </template>>
        </Column>
      </DataTable>
</template>

<script>
import Button from 'primevue/button';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { Utility } from '../../../shared/utils';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
export default {
    name: 'Locations',
    components: {
        Column,
        DataTable,
        InputText,
        Button
    },
    data() {
        return {
            Utility,
            selectedLocation: null,
            searchKeyword: { 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } },
            rowCount: this.locations.length
        };
    },
    props: {
        loading: Boolean,
        locations: Object,
        buildingCode: String
    },
    watch: {
        selectedLocation: function() {
            this.$emit('disableTabs');
        }
    },
    methods: {
        exportCSV(data) {
            this.$emit('downloadData', data);
        },
        countRecord(event) {
            this.rowCount = event.filteredValue.length;
        }
    }
};
</script>
<style>
</style>
