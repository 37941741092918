<template>
    <div>
        <h5 class="mt-3 header-base">
            <div class="row">
                <div class="col-md-6">Scan Activity</div>
                <div v-if="Object.keys(scanActivity).length != 0" class="col-md-6 text-end">
                    <i @click="exportCSV($event)" role="button" class="pi pi-download"></i>
                </div>
            </div>
        </h5>
        <DataTable :value="scanActivity" ref="dt" exportFilename="scan_activity" :loading="loading" responsiveLayout="scroll">
            <Column field="memberName" header="Member Name"></Column>
            <Column field="date" header="Date"></Column>
            <Column field="checkIn" header="Check In"></Column>
            <Column field="break_s" header="Break (Sec)"></Column>
            <Column field="start" header="start"></Column>
            <Column field="end" header="End"></Column>
            <Column field="duration_s" header="Duration (Sec)"></Column>
            <Column field="assetTag" header="Asset Tag"></Column>
            <Column field="question" header="Question"></Column>
            <Column field="answer" header="Answer"></Column>
        </DataTable>
    </div>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
export default {
    name: 'ScanActivity',
    components: {
        Column,
        DataTable
    },
    props: {
        loading: Boolean,
        scanActivity: Object
    },
    methods: {
        exportCSV() {
            this.$refs.dt.exportCSV();
        }
    }
};
</script>
