<template>
    <div>
        <h5 class="mt-3 header-base">
            <div class="row">
                <div class="col-md-6">Team Activity</div>
                <div v-if="Object.keys(teamActivity).length != 0" class="col-md-6 text-end">
                    <i @click="exportCSV($event)" role="button" class="pi pi-download"></i>
                </div>
            </div>
        </h5>
        <DataTable :value="teamActivity" ref="dt" exportFilename="team_activity" :loading="loading" responsiveLayout="scroll">
            <Column field="memberName" header="Member Name"></Column>
            <Column field="date" header="Date"></Column>
            <Column field="break_m" header="Break (Min)"></Column>
            <Column field="checkIn" header="Check In"></Column>
            <Column field="checkOut" header="Check Out"></Column>
            <Column field="duration_m" header="Duration (Min)"></Column>
            <Column field="scanned" header="Scanned"></Column>
            <Column field="new" header="New"></Column>
            <Column field="inConflict" header="In Conflict"></Column>
            <Column field="avg" header="Average (min) / Device"></Column>
        </DataTable>
    </div>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
export default {
    name: 'TeamActivity',
    components: {
        Column,
        DataTable
    },
    props: {
        loading: Boolean,
        teamActivity: Object
    },
    methods: {
        exportCSV() {
            this.$refs.dt.exportCSV();
        }
    }
};
</script>
